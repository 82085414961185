// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------
@import "variables";

.whatsapp-button {
    position: fixed;
    right: 20px;
    bottom: 20px;
    background: #00e676;
    color: #fff;
    border: none;
    width: 50px;
    height: 50px;
    font-size: 32px;
    border-radius: 25px;
    z-index: 10;
}

.rowC {
    display: flex;
    flex-direction: row;
}
.mobile-header {
    height: auto;
}

.mobile-header__address {
    z-index: 0;
    background: "white";
    box-shadow: $nav-panel-shadow;
    color: $nav-panel-font-color;
}

.autocomplete-dropdown-container {
    border-bottom: honeydew;
    border-left: honeydew;
    border-right: honeydew;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1000;
    border-radius: 0 0 2px 2px;
    width: 90%;
}

.mobile-header {
    height: auto;
}
.mobile-header__panel-wrapper {
    height: $nav-panel-height;
}
.mobile-header__panel {
    width: 100%;
    position: fixed;
}

.btn-facebook {
    $btn-primary-bg-color: #3b5998;
    $btn-primary-font-color: #fff;
    @include local-button-variant(
        $btn-primary-bg-color,
        $btn-primary-font-color,
        $btn-primary-icon-color,
        $btn-primary-hover-bg-color,
        $btn-primary-hover-font-color,
        $btn-primary-hover-icon-color,
        $btn-primary-active-bg-color,
        $btn-primary-active-font-color,
        $btn-primary-active-icon-color
    );
}

.product__description__textarea {
    min-width: 270px;
    width: 270px;
    height: auto;
    line-height: 24px;
    min-height: 22px;
    overflow-y: hidden; /* fixes scrollbar flash - kudos to @brettjonesdev */
    padding-top: 1.1em; /* fixes text jump on Enter keypress */
}

// -------------------------
// --- To fix slick slider issue on homepage
// -------------------------

.block-products-carousel__slider {
    pointer-events: none;
}

.block-products-carousel {
    .slick-slide {
        pointer-events: auto;
    }
}

.slick-prevent-click--active {
    .slick-list:before {
        pointer-events: auto;
    }
}

// -------------------------
// --- To fix swiper issue on homepage for desktop
// -------------------------

.swiper-container {
    padding-bottom: 56px;
    margin-bottom: -56px;
    pointer-events: none;

    &:hover {
        z-index: 2;
    }
}
.swiper-slide {
    pointer-events: auto;
}

// -------------------------
// --- custom css - hide the last child on filters
// -------------------------

.widget-filters__list:last-child {
    margin-bottom: -$widget-shop-padding;

    .widget-filters__item:last-child {
        border-bottom: none;
    }
}

// -------------------------
// --- custom css - country phone input
// -------------------------

.PhoneInputCountryIcon {
    width: calc(2.2em * 1.5);
    height: 2.2em;
}

.PhoneInputInput {
    box-shadow: $widget-search-input-default-shadow;
    transition: box-shadow 0.2s;
    border-radius: 2px;
    height: 100%;
    width: 100%;
    background: transparent;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    color: inherit;
    appearance: none;

    @include direction {
        #{$padding-inline-start}: 18px;
        #{$padding-inline-end}: $local-height;
    }

    &:hover {
        box-shadow: $widget-search-input-hover-shadow;
    }
    &:focus {
        outline: none;
        box-shadow: $widget-search-input-focus-shadow;
    }

    &::placeholder {
        color: $widget-search-input-placeholder;
    }
}

// -------------------------
// --- Add separator in checkout
// -------------------------

.checkout__totals-split:not(:last-child) {
    th,
    td {
        border-bottom: 1px solid #ebebeb;
        padding-bottom: 16px;
    }

    & + tr {
        th,
        td {
            padding-top: 16px;
        }
    }
}

// --------------------
// Hide V3 captcha icon
// --------------------

.grecaptcha-badge {
    visibility: hidden;
}

// --------------------
// brand css for product page
// --------------------

.product-brand {
    color: purple;
}

.product-condition {
    color: red;
}

.location-search-input:error {
    border: honeydew;
    display: block;
    width: 10%;
    padding: 16px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
}

// ---------------
// color and size sale price
// ---------------
.product-card__sale-price {
    color: blue;
    font-weight: $font-weight-normal;
    font-size: 13px;
    text-decoration: line-through;
}

.product-card__percentage-price {
    color: blue;
    font-weight: $font-weight-normal;
    font-size: 13px;
}

.product__sale-price {
    color: blue;
    font-weight: $font-weight-normal;
    font-size: 20px;
    text-decoration: line-through;
}

.product__percentage-price {
    color: blue;
    font-weight: $font-weight-normal;
    font-size: 20px;
}

// ---------------
// show bottom checkout button on cart page
// ---------------

.cart-fixed {
    position: fixed;
    background: #fff;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 -1px 3px rgba(#000, 0.1);
    z-index: 100;
}

// ---------------
// show move to top button to the right
// ---------------

.totop__body {
    flex-direction: row-reverse;
}

.totop__end {
    flex-direction: row;
}

.totop__button {
    @include direction {
        #{$margin-inline-start}: $local-margin-end;
        #{$margin-inline-end}: $local-margin-start;
    }
}

// ---------------
// show cart to page on desktop mode
// ---------------

.dropcart {
    max-height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
}

.dropcart__products-list {
    overflow-y: auto;
}

// ---------------
// show view more results in suggestion box
// ---------------

.suggestions__footer {
    $local-padding-y: 14px;

    @include direction {
        text-align: $inline-end;
    }
    padding: $local-padding-y 18px ($local-padding-y - 8px);
    font-size: 15px;

    a:hover {
        text-decoration: underline;
    }
}

// ---------------
// show date picker to select day for delivery
// ---------------

.block-slideshow--layout--datepicker {
    $local-margin: 15px;

    .block-slideshow__body {
        margin-top: $local-margin;
        height: 125px - $local-margin;
    }
    .block-slideshow__slide {
        height: 125px - $local-margin;
    }

    .block-slideshow__slide:hover {
        transform: scale(1.3);
        background: #ff800a;
        z-index: 2;
    }

    .block-features__item {
        flex-direction: column;
        background: $block-features-boxed-bg;
        border-radius: 1px;
        text-align: center;
        align-items: center;
        padding: 30px 28px 28px;
    }

    .block-features__content:hover {
        transform: scale(1.1);
        z-index: 2;
    }

    @media (min-width: breakpoint(lg-start)) {
        .block-slideshow__body {
            @include direction {
                #{$margin-inline-start}: (30px - $local-margin) * -1;
            }
        }
    }

    @media (max-width: breakpoint(md-end)) {
        .block-slideshow__slide-button .btn {
            @include btn-sm();
        }
    }
}

.postal-code {
    justify-content: center;
    display: flex;
}

.suggestions__item-price-new {
    color: $price-new-color;
}
.suggestions__item-price-old {
    color: $price-old-color;
    font-weight: $font-weight-normal;
    font-size: 13px;
    text-decoration: line-through;
}
.product-card__image {
    @media (min-width: breakpoint(md-start)) {
        img {
            max-height: 180px;
            display: block;
            position: absolute;
        }
    }
    @media (max-width: breakpoint(sm-end)) {
        img {
            max-height: 450px;
        }
    }
}
